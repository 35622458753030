import { Button } from "@my/ui/src/buttons/Button"
import { Icon } from "@my/ui/src/icon/Icon"
import { InputRow } from "@my/ui/src/inputRow/InputRow"
import { ImageWithPlaceholder } from "@my/ui/src/placeholders/ImageWithPlaceholder"
import { useSubscriptionStore } from "app/global-state/subscriptionsStore"
import { mixpanel, trackButtonClicked } from "app/telemetry"
import { useFetchSubscriptions, useToggleSubscription } from "../../hooks/useSubscriptions"
import React, { FC, useMemo, useState, useEffect, useCallback } from "react"
import { createParam } from "solito"
import { useRouter } from "solito/router"
import {
  H1,
  H2,
  H6,
  Paragraph,
  ScrollView,
  useMedia,
  View,
  ViewProps,
  YStack,
  isWeb,
  useWindowDimensions,
  SizableText,
} from "tamagui"
import { Program, useAuth } from "@my/api"
import { useStorage } from "app/storage/useStorage"
import { useToastController } from "@tamagui/toast"
import { usePrograms } from "./programQueries"

type ProgramDetailsParams = {
  programPath: string
}

const { useParams } = createParam<ProgramDetailsParams>()

interface ProgramsDetailsPageProps extends ViewProps {
  renderSeoMetadata?: (program: Program) => React.ReactNode
  alignment?: "left" | "center"
}

export const ProgramsDetailsPage: FC<ProgramsDetailsPageProps> = ({
  renderSeoMetadata,
  alignment = "left",
}) => {
  const { params } = useParams()
  const { gtSm } = useMedia()
  const router = useRouter()
  const { subscriptions } = useSubscriptionStore()
  const [fetchedSubscriptions, setFetchedSubscriptions] = useState(false)
  const toggleSubscription = useToggleSubscription()
  const { isAuthenticated, signup } = useAuth()
  const storage = useStorage()
  const [isInitializing, setIsInitializing] = useState(true)
  const [module, setModule] = useState<Program | undefined>()
  const toast = useToastController()
  const { width: windowWidth } = useWindowDimensions()

  const maxImageWidth = 760
  const imageWidth = windowWidth < maxImageWidth ? windowWidth : maxImageWidth
  const imageHeight = Math.round((imageWidth * 9) / 16)

  // Fetch data hooks at top level
  const { data: programs } = usePrograms()
  const fetchSubscriptions = useFetchSubscriptions()

  // Get module after data is fetched
  useEffect(() => {
    setIsInitializing(true)
    ;(async () => {
      if (isAuthenticated && !fetchedSubscriptions) {
        await fetchSubscriptions()
        setFetchedSubscriptions(true)
      }

      const foundModule = programs?.find((m) => m.path == params?.programPath)
      if (foundModule) {
        setModule(foundModule)
      }
      setIsInitializing(false)
    })()
  }, [params?.programPath, programs, fetchSubscriptions, fetchedSubscriptions, isAuthenticated])

  // Check if the module is subscribed to
  const isSubscribed = useMemo(
    () => (subscriptions ?? []).some((subscription) => subscription.moduleId === module?.id),
    [subscriptions, module],
  )
  const pageName = isWeb && !isAuthenticated ? "ProgramDetailLoggedOut" : "ProgramDetail"

  const TrackPage = () => {
    mixpanel.trackOnMount({
      page_name: pageName,
      module_name: module?.name,
      logged_out: !isAuthenticated,
    })
    return null
  }

  // Handle subscription toggle
  const handleToggleSubscription = async (enable: boolean = false) => {
    try {
      if (!module) return

      const result = await toggleSubscription(enable, module.id)
      if (result.error) {
        toast.show(result.error)
        return
      }
    } catch (error) {
      console.error("Failed to toggle subscription:", error)
    }
  }

  const handleBack = useCallback(() => {
    router.back()
  }, [router])

  // Redirect if module is not found after loading
  useEffect(() => {
    /*
     * On initial load, isLoading is false, module is undefined, and modules is undefined or empty (fetch hasn't happened).
     * So this check ensures we're finished fetching and have modules synced and only then will we redirect. What happens
     * is if you refresh while viewing a module, without this 'modules' check here, you'll be redirected back.
     */
    if (!isInitializing && !module) {
      handleBack()
    }
  }, [isInitializing, module, router, handleBack])

  // If the module is null, don't render anything
  if (!module) {
    return null
  }

  return (
    <ScrollView
      maxWidth="100%"
      contentContainerStyle={{ alignItems: alignment === "center" ? "center" : "flex-start" }}
      flex={1}
    >
      {isWeb && module && renderSeoMetadata?.(module)}
      <View paddingHorizontal="$base" width={760} maxWidth="100%">
        <View marginBottom="$base">
          <Button
            icon={<Icon icon="chevron-left" size={isWeb ? "1x" : 16} />}
            variant="tertiary"
            width={90}
            paddingHorizontal="$xs"
            testID="programs-details-back-button"
            onPress={() => {
              trackButtonClicked("BackButton", pageName, {
                module_name: module?.name,
                logged_out: !isAuthenticated,
              })
              handleBack()
            }}
          >
            Back
          </Button>
        </View>

        {gtSm ?
          <H1 fontWeight={700} marginBottom="$sm" testID="programs-details-header">
            {module?.name}
          </H1>
        : <H2 fontWeight={700} marginBottom="$sm" testID="programs-details-header">
            {module?.name}
          </H2>
        }

        <ImageWithPlaceholder
          imageProps={{
            aspectRatio: "16 / 9",
            height: imageHeight,
            width: imageWidth,
            maxHeight: "100%",
            maxWidth: "100%",
            borderRadius: "$6",
            source: {
              uri: module?.imageUrl,
              height: imageHeight,
              width: imageWidth,
            },
          }}
          placeholderProps={{
            height: imageHeight,
            width: imageWidth,
            maxHeight: "100%",
            maxWidth: "100%",
          }}
        />

        {!isAuthenticated ?
          <View marginTop="$base">
            <Button
              variant="primary"
              width="100%"
              maxWidth="100%"
              onPress={() => {
                trackButtonClicked("StartProgram", pageName, {
                  module_name: module?.name,
                  logged_out: !isAuthenticated,
                })
                if (module) {
                  storage.set("pendingModuleSubscriptionId", module.id)
                  signup()
                }
              }}
            >
              Start this program now
            </Button>
          </View>
        : <View
            backgroundColor="$backgroundMessage"
            borderRadius="$6"
            marginTop="$base"
            padding="$base"
            paddingLeft="$sm"
            testID="programs-details-start-program-input-row"
          >
            <InputRow
              editable
              inputType="switch"
              label="Start this program now"
              value={isSubscribed}
              labelProps={{
                minWidth: "$10",
              }}
              onChange={(value) => {
                trackButtonClicked("ProgramToggle", pageName, {
                  toggle_state: isSubscribed ? "off" : "on",
                  module_name: module?.name,
                  logged_out: !isAuthenticated,
                })
                handleToggleSubscription(Boolean(value))
              }}
            />
          </View>
        }

        <View marginVertical="$base">
          <Paragraph
            size="$5"
            lineHeight="$9"
            marginTop="$xs"
            testID="programs-details-description"
          >
            {module?.description}
          </Paragraph>

          <H6 marginTop="$sm" testID="programs-details-checkin-about">
            We'll check in with you about
          </H6>

          <YStack>
            {module?.checkInItems.map((value, index) => (
              <Paragraph size="$5" lineHeight="$9" color="$textSecondary" key={index}>
                &nbsp;&#8226;&nbsp;
                <SizableText size="$5" lineHeight="$9" color="$text" fontWeight={700}>
                  {value.name}
                </SizableText>
                : {value.rationale}
              </Paragraph>
            ))}
          </YStack>
        </View>
      </View>
      {!isInitializing && <TrackPage />}
    </ScrollView>
  )
}
