import { useState } from "react"
import { SizableText, Popover, Button, type PopoverProps, isWeb } from "tamagui"
import { Platform } from "react-native"

// Tamagui tooltip does not support native, so we're emulating tooltip behavior with the tamagui popover
export function PopoverTooltip({
  Trigger,
  children,
  ...props
}: PopoverProps & { Trigger?: React.ReactNode }) {
  const [isPopoverVisible, setIsPopoverVisible] = useState(false)

  const handleMouseEnter = () => {
    setIsPopoverVisible(true)
  }

  const handleMouseLeave = () => {
    setIsPopoverVisible(false)
  }

  const handleTouchStart = () => {
    setIsPopoverVisible((prev) => !prev)
  }

  return (
    <Popover {...props} open={isPopoverVisible} onOpenChange={setIsPopoverVisible}>
      <Popover.Trigger>
        <Button
          unstyled
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onPress={!isWeb ? handleTouchStart : undefined}
        >
          {Trigger}
        </Button>
      </Popover.Trigger>
      <Popover.Content
        top={Platform.OS === "android" ? 50 : undefined}
        enterStyle={{ x: 0, y: -5, opacity: 0, scale: 0.9 }}
        exitStyle={{ x: 0, y: -5, opacity: 0, scale: 0.9 }}
        scale={1}
        x={0}
        y={0}
        opacity={1}
      >
        <Popover.Arrow />
        <SizableText size="$2" lineHeight="$1">
          {children}
        </SizableText>
      </Popover.Content>
    </Popover>
  )
}
