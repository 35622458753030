import { FC, useState } from "react"
import {
  Tooltip as TamaguiTooltip,
  TooltipProps as TamaguiTooltipProps,
  SizableText,
  ColorTokens,
} from "tamagui"

export interface TooltipProps extends TamaguiTooltipProps {
  TriggerComponent: FC
  contentProps?: any
  backgroundColor?: ColorTokens
  color?: ColorTokens
}

export const Tooltip: FC<TooltipProps> = ({
  children,
  TriggerComponent,
  contentProps,
  backgroundColor = "$color",
  color = "$background",
  ...props
}) => {
  const [tooltipVisible, setTooltipVisible] = useState(false)

  const tooltipContentProps = {
    enterStyle: { x: 0, y: -5, opacity: 0, scale: 0.9 },
    exitStyle: { x: 0, y: -5, opacity: 0, scale: 0.9 },
    scale: 1,
    x: 0,
    y: 0,
    opacity: 1,
    backgroundColor: backgroundColor as ColorTokens,
    padding: "$xs",
    paddingHorizontal: "$sm",
    animation: [
      "quick",
      {
        opacity: {
          overshootClamping: true,
        },
      },
    ],
    ...contentProps,
  }

  return (
    <TamaguiTooltip
      placement="bottom"
      open={tooltipVisible}
      onOpenChange={setTooltipVisible}
      {...props}
    >
      <TamaguiTooltip.Trigger onPress={() => setTooltipVisible(true)}>
        <TriggerComponent />
      </TamaguiTooltip.Trigger>

      <TamaguiTooltip.Content {...tooltipContentProps}>
        <TamaguiTooltip.Arrow style={{ backgroundColor }} />

        <SizableText color={color as ColorTokens} fontSize="$4">
          {children}
        </SizableText>
      </TamaguiTooltip.Content>
    </TamaguiTooltip>
  )
}
