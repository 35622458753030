import { isChangeEvent, isSyntheticEvent } from "@my/utils/src/eventUtils/eventUtils"
import { ChangeEvent, ComponentProps, FC, useCallback, useMemo } from "react"
import { NativeSyntheticEvent, Platform, TextInputChangeEventData } from "react-native"
import type { GetThemeValueForKey, StackProps } from "tamagui"
import { isWeb, Switch, SizableText, useMedia, XStack, YStack, View, useTheme } from "tamagui"
import { DateTimePicker } from "../datePicker/DateTimePicker"
import { Icon, IconSize } from "../icon/Icon"
import type { RowColorType } from "../inputs/StyledInputs"
import { RowInput } from "../inputs/StyledInputs"
import { Option, Options, SelectInput } from "../select/Select"
import { PopoverTooltip } from "../tooltip/PopoverTooltip"
import { Tooltip } from "../tooltip/Tooltip"

export const InputComponents = {
  date: (props: ComponentProps<typeof DateTimePicker>) => <DateTimePicker {...props} />,
  email: (props: ComponentProps<typeof RowInput>) => <RowInput {...props} inputMode="email" />,
  number: (props: ComponentProps<typeof RowInput>) => <RowInput {...props} inputMode="numeric" />,
  password: (props: ComponentProps<typeof RowInput>) => <RowInput {...props} secureTextEntry />,
  search: (props: ComponentProps<typeof RowInput>) => <RowInput {...props} inputMode="search" />,
  select: (props: ComponentProps<typeof SelectInput>) => <SelectInput {...props} />,
  switch: (props: ComponentProps<typeof Switch>) => (
    <Switch {...props}>
      <Switch.Thumb animation="quick" backgroundColor="$switchThumb" />
    </Switch>
  ),
  tel: (props: ComponentProps<typeof RowInput>) => <RowInput {...props} inputMode="tel" />,
  text: (props: ComponentProps<typeof RowInput>) => <RowInput {...props} inputMode="text" />,
  url: (props: ComponentProps<typeof RowInput>) => <RowInput {...props} inputMode="url" />,
} as const

export type InputComponentType = keyof typeof InputComponents

/**
 * Props for customizing the label in InputRow component
 * @interface LabelProps
 * @extends {StackProps}
 */
export interface LabelProps extends StackProps {
  /** Font size of the label text, can be a number or string value */
  fontSize?: number | string
  /** Color token for the label text */
  color?: RowColorType
  /** Left padding of the label, can be a number, or theme space token */
  paddingLeft?: number | GetThemeValueForKey<"space">
  /**
   * Minimum width of the label, can be a number or theme minWidth token
   */
  minWidth?: number | GetThemeValueForKey<"minWidth">
  numberOfLines?: number
}

export interface InputRowProps extends StackProps {
  checked?: boolean
  color?: RowColorType
  condensed?: boolean
  disabled?: boolean
  editable: boolean
  helpText?: string
  inputType: InputComponentType
  label?: string
  labelTestID?: string
  maxDate?: Date
  minDate?: Date
  onChange: (value: string | number | boolean | Date | null | undefined) => void
  options?: Options
  placeholder?: string
  value: string | number | boolean | Date | null | undefined
  valueTestID?: string
  labelProps?: LabelProps
  variant?: "inline" | "stacked"
  paddingRight?: number | GetThemeValueForKey<"space">

  /**
   * Boolean flag to indicate whether to include time in the input.
   * This is applicable only for date input types.
   */
  includeTime?: boolean
}

export const InputRow: FC<InputRowProps> = ({
  children,
  checked,
  condensed,
  disabled,
  editable,
  helpText,
  inputType = "text",
  onChange,
  placeholder,
  value,
  label,
  labelTestID,
  valueTestID,
  labelProps,
  variant = "inline",
  includeTime,
  paddingRight,
  ...props
}): JSX.Element => {
  const theme = useTheme()
  const { xxs, sm } = useMedia()

  const inputColor = editable ? "$textLink" : "$textSecondary"
  const InputComponent = useMemo(() => InputComponents[inputType], [inputType])

  const handleChange = useCallback(
    (
      e:
        | ChangeEvent<HTMLInputElement>
        | NativeSyntheticEvent<TextInputChangeEventData>
        | Option
        | string
        | number
        | boolean
        | unknown
        | null,
    ) => {
      let newValue: string | number | boolean | Date | null | undefined = null

      // If the event is a SyntheticEvent, we assume it's a text input change
      if (isSyntheticEvent(e)) {
        newValue = e.nativeEvent.text
      }
      // If it's a ChangeEvent, we assume it's a DOM input event (web)
      else if (isChangeEvent(e)) {
        newValue = e.target.value
      }
      // If it's anything else, treat it as a direct value
      else {
        newValue = e as string | number | boolean
      }

      onChange(newValue as string | number | boolean | Date | null | undefined)
    },
    [onChange],
  )

  const inputProps =
    inputType === "date" ?
      {
        ...props,
        color: inputColor as RowColorType,
        editable,
        flex: 2,
        focusable: editable,
        name: label,
        onChange: handleChange,
        placeholder: placeholder,
        placeholderTextColor: inputColor,
        selectTextOnFocus: editable,
        value,
        includeTime,
        paddingRight,
      }
    : inputType !== "switch" ?
      {
        ...props,
        color: inputColor as RowColorType,
        editable,
        flex: 2,
        focusable: editable,
        name: label,
        onChange: handleChange,
        placeholder: placeholder,
        placeholderTextColor: inputColor,
        selectTextOnFocus: editable,
        value,
      }
    : {
        ...props,
        disabled: disabled || !editable,
        native: isWeb ? false : Platform.OS,
        nativeProps: {
          ...props,
          onCheckedChange: onChange,
          checked: value,
          value,
          disabled: disabled || !editable,
          trackColor: {
            false: theme.switchOff.val,
            true: theme.switchOn.val,
          },
          thumbColor: theme.switchThumb.val,
          borderColor: value || checked ? theme.switchOn.val : theme.switchOff.val,
        },
        onCheckedChange: handleChange,
        checked: value,
        value,
        backgroundColor: value || checked ? "$switchOn" : "$switchOff",
        borderColor: value || checked ? "$switchOn" : "$switchOff",
        cursor: "pointer",
      }

  const Label = () => {
    return (
      <XStack
        flex={1}
        minWidth={labelProps?.minWidth || (xxs ? "$8" : "$10")}
        width="30%"
        alignItems="center"
        alignContent="center"
        gap="$xs"
      >
        <SizableText
          testID={labelTestID}
          numberOfLines={labelProps?.numberOfLines}
          ellipsizeMode="tail"
          {...(labelProps?.fontSize && {
            fontSize: labelProps.fontSize as GetThemeValueForKey<"fontSize">,
          })}
          {...(labelProps?.color && { color: labelProps.color })}
        >
          {label}
        </SizableText>

        {!!helpText &&
          (() => {
            return isWeb ?
                <Tooltip
                  placement="bottom"
                  TriggerComponent={() => <Icon icon="circle-question" size={IconSize.Small} />}
                >
                  {helpText}
                </Tooltip>
              : <PopoverTooltip Trigger={<Icon icon="circle-question" size={IconSize.Small} />}>
                  {helpText}
                </PopoverTooltip>
          })()}
      </XStack>
    )
  }

  if (variant === "stacked") {
    return (
      <YStack>
        <Label />
        <View paddingVertical="$xs" alignItems="flex-start" testID={valueTestID}>
          {(children ?? InputComponent) ?
            //@ts-expect-error Component needs refactoring to make typesafe
            <InputComponent {...inputProps} />
          : null}
        </View>
      </YStack>
    )
  }

  // inline layout
  return (
    <XStack
      alignItems="center"
      flex={3}
      minHeight={
        condensed ? "$2"
        : sm ?
          "$4"
        : "$5"
      }
      paddingVertical={props.paddingVertical ?? "$0"}
      justifyContent="space-between"
      paddingHorizontal="$0"
      paddingLeft={labelProps?.paddingLeft ?? "$sm"}
      width="100%"
    >
      <Label />
      <XStack
        alignItems="center"
        flex={2}
        justifyContent="flex-end"
        $sm={{
          marginRight: "$0",
        }}
        $gtSm={{
          marginRight: "$xs",
        }}
        testID={valueTestID}
      >
        {(children ?? InputComponent) ?
          //@ts-expect-error Component needs refactoring to make typesafe
          <InputComponent {...inputProps} />
        : null}
      </XStack>
    </XStack>
  )
}
